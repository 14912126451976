/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as fs from 'filestack-js';
import { environment } from 'src/environments/environment';
import * as fsa from 'filestack-adaptive';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Buffer } from 'buffer';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FilestackService {
    // initFileStack =
    constructor(private http: HttpClient) {
        fs.init(environment.fileStackAPIKey);
    }

    setImgResolution(
        url: string,
        width: string,
        height: string,
        alt: string,
        resizeWidth?: number,
        resizeHeight?: number,
        isCropImage = true,
        radius: number | null = null
    ) {
        // let options: any;
        const linkUrl = new window.URL(url);

        const options: any = {
            alt: alt,
            width: width,
            // height: height,
            resolutions: ['1x', '2x'],
            transforms: {
                // quality: {
                //     value: 90,
                // },
                resize: {
                    width: resizeWidth,
                    height: resizeHeight,
                },
            },
            formats: ['webp', 'jpg'],
        };

        if (isCropImage) {
            options.transforms.resize.fit = fs.EFitOptions.crop;
        }

        if (radius) {
            options.transforms = {
                ...options.transforms,
                rounded_corners: {
                    radius: 5,
                },
            };
        }

        if (linkUrl.hostname !== 'cdn.filestackcontent.com') {
            return fsa.makePictureTree(
                {
                    srcHandle: url,
                    apiKey: environment.fileStackAPIKey,
                },
                options
            );
        }
        return fsa.makePictureTree(this.imgUrl(url), options);
    }

    // setImgResolutionFitMax(
    //     url: string,
    //     width: string,
    //     height: string,
    //     alt: string,
    //     resizeWidth?: number,
    //     resizeHeight?: number,
    //     isCropImage = true,
    //     radius: number | null = null
    // ) {
    //     // let options: any;
    //     const linkUrl = new window.URL(url);

    //     const options: any = {
    //         alt: alt,
    //         width: width,
    //         // height: height,
    //         resolutions: ['1x', '2x'],
    //         transforms: {
    //             // quality: {
    //             //     value: 90,
    //             // },
    //             resize: {
    //                 width: resizeWidth,
    //                 height: resizeHeight,
    //                 fit: 'max',
    //             },
    //         },
    //         formats: ['webp', 'jpg'],
    //     };

    //     if (isCropImage) {
    //         options.transforms.resize.fit = fs.EFitOptions.crop;
    //     }

    //     if (radius) {
    //         options.transforms = {
    //             rounded_corners: {
    //                 radius: 5,
    //             },
    //         };
    //     }

    //     if (linkUrl.hostname !== 'cdn.filestackcontent.com') {
    //         return fsa.makePictureTree(
    //             {
    //                 srcHandle: url,
    //                 apiKey: environment.fileStackAPIKey,
    //             },
    //             options
    //         );
    //     }
    //     return fsa.makePictureTree(this.imgUrl(url), options);
    // }
    imgUrl(url: any) {
        return url.split('/').splice(-1)[0];
    }

    // uploadFile(file: fs.InputFile) {
    //     const client = fs.init(environment.fileStackAPIKey);

    //     return new Promise((resolve, reject) => {
    //         client
    //             .upload(file)
    //             .then((res) => resolve(res))
    //             .catch((err) => reject(err));
    //     });
    // }

    // trasformImage(url?: string, resizeWidth?: number, resizeHeight?: number, radius = 1) {
    //     // console.log('url==========', url);
    //     let linkURL: any;
    //     if (!url) {
    //         return null;
    //     } else {
    //         linkURL = new window.URL(url);
    //     }

    //     if (linkURL.hostname !== 'cdn.filestackcontent.com') {
    //         return `https://cdn.filestackcontent.com/${environment.fileStackAPIKey}/resize=width:${resizeWidth},height:${resizeHeight}/rounded_corners=radius:${radius}/${url}`;
    //     } else {
    //         return `https://cdn.filestackcontent.com/resize=width:${resizeWidth},height:${resizeHeight}/rounded_corners=radius:${radius}/${this.imgUrl(
    //             url
    //         )}`;
    //     }
    // }

    getBlobFormImageURL(url: string) {
        return this.http.get(`${environment.fcfApiUrl}/v2mime/image?url=${url}`, {
            responseType: 'blob', // Ensure the response type is blob for binary data
        });
    }

    urlsafeBase64Encode(url: string) {
        // Encode the URL to Base64
        let base64Url = btoa(url);
        // Make it URL-safe by replacing '+' with '-' and '/' with '_'
        base64Url = base64Url.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return base64Url;
    }

    //  to split the encoded URL every n characters
    splitEncodedUrl(URL: string, n: number, width: number, height: number, resizingType = 'force') {
        if (URL) {
            if (URL.includes(environment.GCS_URL)) {
                let fileNameURL = this.decodeUrlSafeBase64(URL.split('sig')[1].replace(/\//g, ''));
                let lastSlashIndex: any;
                let fileName: any;
                let name: any;
                let splitUrl: any;

                // console.log('==================', fileNameURL);

                if (fileNameURL.includes('file')) {
                    fileName = this.decodeUrlSafeBase64(URL.split('sig')[1].replace(/\//g, ''))
                        .substring(lastSlashIndex)
                        .replace('/', '');

                    name = this.isUriEncoded(fileName)
                        ? fileName
                        : this.hasQueryParams(fileNameURL)
                            ? encodeURIComponent(fileName)
                            : fileName;

                    splitUrl = URL.split('sig')[1];
                } else {
                    lastSlashIndex = fileNameURL.lastIndexOf('/');
                    fileName = this.decodeUrlSafeBase64(URL.split('sig')[1].replace(/\//g, ''))
                        .substring(lastSlashIndex)
                        .replace('/', '');

                    name = this.isUriEncoded(fileName)
                        ? fileName
                        : this.hasQueryParams(fileNameURL)
                            ? encodeURIComponent(fileName)
                            : fileName;

                    const encodedUrl = this.urlsafeBase64EncodeUTF8(`gs://${environment.BUCKET_NAME}/` + name) as any;

                    splitUrl = encodedUrl.match(new RegExp('.{1,' + n + '}', 'g')).join('/');
                }

                if (this.isGif(fileName) && fileName.lastIndexOf('.') !== -1) {
                    return {
                        img:
                            URL.split('sig')[0] +
                            'sig/' +
                            `resize:${resizingType}:${width}:${height}:0/` +
                            splitUrl +
                            '.gif',
                        imageName: splitUrl,
                        type: resizingType || 'force',
                        width: width,
                        height: height,
                        mainSrc: URL.split('sig')[0] + 'sig/' + splitUrl,
                    };
                } else {
                    // console.log(
                    //     '==================sdsdsodso===0opopsp=',
                    //     URL.split('sig')[0] + 'sig/' + `resize:${resizingType}:${width}:${height}:0/` + splitUrl
                    // );
                    return {
                        img: URL.split('sig')[0] + 'sig/' + `resize:${resizingType}:${width}:${height}:0/` + splitUrl,
                        imageName: splitUrl,
                        type: resizingType || 'force',
                        width: width,
                        height: height,
                        mainSrc: URL.split('sig')[0] + 'sig/' + splitUrl,
                    };
                }
            } else {
                const encodedUrl = this.urlsafeBase64EncodeUTF8(URL) as any;

                let splitUrl = encodedUrl.match(new RegExp('.{1,' + n + '}', 'g')).join('/');
                return {
                    img: `${environment.GCS_URL}/sig/resize:${resizingType}:${width}:${height}:0/${splitUrl}`,
                    imageName: splitUrl,
                    type: resizingType || 'force',
                    width: width,
                    height: height,
                    mainSrc: URL.split('sig')[0] + 'sig/' + splitUrl,
                };
            }
        } else {
            return {
                img: '',
                imageName: '',
                type: resizingType || 'force',
                width: width,
                height: height,
                mainSrc: URL?.split('sig')[0] + 'sig/' + '',
            };
        }
    }

    getDefaultReturnObject(resizingType: string, width: number, height: number, URL: string) {
        return {
            img: '',
            imageName: '',
            type: resizingType || 'force',
            width: width,
            height: height,
            mainSrc: URL?.split('sig=')[0] + 'sig/' + '',
        };
    }

    urlsafeBase64EncodeUTF8(input: string): string {
        const utf8Bytes = new TextEncoder().encode(input); // Convert the string to UTF-8 bytes
        const base64String = btoa(String.fromCharCode(...utf8Bytes)); // Encode to Base64
        return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Make it URL-safe
    }

    hasQueryParams(url: string) {
        const regex = /\?.+=.+$/;
        return regex.test(url);
    }

    isUriEncoded(str: string) {
        try {
            // Decode the string and encode it back
            return str === encodeURIComponent(decodeURIComponent(str));
        } catch (e) {
            // If decodeURIComponent throws an error, the string is not properly encoded
            return false;
        }
    }

    isGif(imageUrl: string): boolean {
        return imageUrl.toLowerCase().endsWith('.gif');
    }

    dowloadFile(url: string) {
        const handler = this.imgUrl(url);
        return `https://www.filestackapi.com/api/file/${handler}?dl=true`;
    }

    blobToBase64(blob: Blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    downloadPdfFile(URL: string) {
        // console.log('=======================', this.decodeUrlSafeBase64(URL.split('sig')[1].replace(/\//g, '')));
        let headers = new HttpHeaders();
        headers = headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
        const fileNameURL = this.decodeUrlSafeBase64(URL.split('sig')[1].replace(/\//g, ''));
        const lastSlashIndex = fileNameURL.lastIndexOf('/');
        console.log('=======', fileNameURL);
        this.http
            .get(`${environment.fcfApiUrl}/v2mime/download/${URL.split('sig')[1].replace(/\//g, '')}`, {
                responseType: 'blob', // Ensure the response type is blob for binary data
                headers: headers,
            })
            .subscribe(
                (blob: any) => {
                    console.log('======================== singlr blob', blob);

                    const url = window.URL.createObjectURL(blob);
                    // Create a link element and trigger a click to download the file
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = this.decodeUrlSafeBase64(URL.split('sig')[1].replace(/\//g, ''))
                        .substring(lastSlashIndex)
                        .replace('/', '');
                    document.body.appendChild(a);
                    a.click();
                    // Cleanup: remove the link and revoke the object URL
                    a.remove();
                    // window.URL.revokeObjectURL(url);
                },
                (error) => {
                    console.error('Upload Error:', error);
                }
            );
    }
    downloadEventFeedData(urls: string[]) {
        return new Promise((resolve) => {
            this.http
                .post(`${environment.fcfApiUrl}/v2mime/download/feedData`, { fileName: urls })
                .subscribe((fileRes: any) => {
                    const a = document.createElement('a');
                    setTimeout(() => {
                        a.href = fileRes.data[0];
                        a.download = 'eventFeed.zip';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        resolve(true);
                    }, 4000);
                });
        });
    }
    viewPdfFile(URL: string) {
        const fileNameURL = this.decodeUrlSafeBase64(URL.split('sig')[1].replace(/\//g, ''));
        const lastSlashIndex = fileNameURL.lastIndexOf('/');
        return `${environment.fcfApiUrl}/v2mime/download/${URL.split('sig')[1].replace(/\//g, '')}`;
        // this.http
        //     .get(`${environment.fcfApiUrl}/v2mime/download/${URL.split('sig')[1].replace(/\//g, '')}`, {
        //         responseType: 'blob',
        //     })
        //     .subscribe(
        //         (blob: any) => {
        //             console.log(blob);
        //             const url = window.URL.createObjectURL(blob);
        //             // Create a link element and trigger a click to download the file
        //             const a = document.createElement('a');
        //             a.href = url;
        //             a.download = this.decodeUrlSafeBase64(URL.split('sig')[1].replace(/\//g, ''))
        //                 .substring(lastSlashIndex)
        //                 .replace('/', '');
        //             console.log('===== >>>> ', a.download, a.href);

        //             document.body.appendChild(a);

        //             return `${environment.fcfApiUrl}/v2mime/download/${URL.split('sig')[1].replace(/\//g, '')}`;
        //         },
        //         (error) => {
        //             console.error('Upload Error:', error);
        //         }
        //     );
    }

    decodeUrlSafeBase64(encodedString: string): string {
        // Replace '-' with '+' and '_' with '/'
        let normalizedEncodedString = encodedString.replace(/-/g, '+').replace(/_/g, '/');
        // Add padding if necessary
        const paddingLength = normalizedEncodedString.length % 4;
        if (paddingLength) {
            normalizedEncodedString += '='.repeat(4 - paddingLength);
        }

        const decodedData = atob(normalizedEncodedString);

        // Decode the URI component
        // const decodedUrl = decodeURIComponent(escape(decodedData));

        return decodedData;

        // Decode the Base64 string using Buffer (Node.js environment)
        // const decodedBuffer = Buffer.from(normalizedEncodedString, 'base64');
        // return decodedBuffer.toString('utf-8'); // Use 'utf-8' to properly handle special characters
    }

    getVideoStream(url: string, range = 'bytes=0-') {
        const fileNameURL = this.decodeUrlSafeBase64(url.split('sig')[1].replace(/\//g, ''));
        const lastSlashIndex = fileNameURL.lastIndexOf('/');
        const fileName = this.decodeUrlSafeBase64(url.split('sig')[1].replace(/\//g, ''))
            .substring(lastSlashIndex)
            .replace('/', '')
            .replace('/', '');
        const headers = new HttpHeaders().set('Range', range);
        // console.log(
        //     '${environment.fcfApiUrl}/v2mime/video/${encodeURI(fileName)}',
        //     `${environment.fcfApiUrl}/v2mime/video/${encodeURIComponent(fileName)}`
        // );
        return this.http
            .get(`${environment.fcfApiUrl}/v2mime/video?url=${encodeURIComponent(fileName)}`, {
                headers,
                responseType: 'blob',
            })
            .pipe(
                map((resp) => {
                    return {
                        [url]: URL.createObjectURL(resp),
                    };
                })
            );
    }

    getVideoStreamURL(url: string) {
        const fileNameURL = this.decodeUrlSafeBase64(url.split('sig')[1].replace(/\//g, ''));
        const lastSlashIndex = fileNameURL.lastIndexOf('/');
        const fileName = this.decodeUrlSafeBase64(url.split('sig')[1].replace(/\//g, ''))
            .substring(lastSlashIndex)
            .replace('/', '')
            .replace('/', '');
        return `${environment.fcfApiUrl}/v2mime/video?url=${encodeURIComponent(fileName)}`;
    }

    uploadFile2(file: any, name = '', type = '') {
        return new Promise((resolve, reject) => {
            const formData: any = {};
            if (typeof file === 'string' && file.includes('base64')) {
                formData['fileName'] = name;
                formData['image'] = file;
                formData['type'] = type || 'image/jpeg';

                this.http.post(`${environment.fcfApiUrl}/v2mime/upload-images`, formData).subscribe(
                    (event: any) => {
                        resolve({
                            ...event,
                            name: name,
                            url: event.gsutilURI,
                        });
                    },
                    (error) => {
                        console.error('Upload Error:', error);
                    }
                );
            } else {
                const fr = new FileReader();
                const image: any = file;
                // fr.onload = () => {
                const img = new Image() as any;
                console.log('FR.Result');
                // img.src = fr.result;
                // formData['file'] = file; //Not handling multipart/form-data for fastify update
                const lastDotIndex = file.name.lastIndexOf('.');
                const name = file.name.substring(0, lastDotIndex);
                const shortenedName = name.length <= 6 ? name : name.substring(0, 6);
                const extension = file.name.substring(lastDotIndex);

                const timestamp = moment().valueOf();

                // Construct new file name with timestamp
                const newFileName = `${shortenedName}_${timestamp}${extension}`;
                // Create a new File object with the same content and the new name
                const renamedFile = new File([file], newFileName, { type: file.type });
                formData['fileName'] = renamedFile.name;
                formData['image'] = img.src;
                formData['type'] = image.type;

                const payload = {
                    fileName: formData?.fileName,
                    type: formData?.type,
                };

                this.generateSignedUrl(payload).subscribe((response: any) => {
                    const url = response.url;
                    if (url) {
                        fetch(url, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': formData.type,
                                'x-goog-upload-file-name': formData.fileName,
                                'x-goog-upload-protocol': 'raw',
                            },
                            body: file, // File blob to upload
                        }).then((response) => {
                            if (!response.ok) {
                                response.text().then((errorText) => {
                                    console.error('Upload failed:', errorText);
                                });
                            } else {
                                console.log('Upload successful:', response);
                                const gsutilURI = `gs://${environment.BUCKET_NAME}/${formData.fileName}`;
                                console.log('------ gsUtilUrl', gsutilURI);
                                const url = this.newSplitEncodedUrl(gsutilURI, 10);
                                resolve({
                                    name: name,
                                    gsutilURI: url,
                                    url: url,
                                });
                            }
                        });
                    }
                });

                // this.http
                //     .post(`http://localhost:5000/dev-hellocrowd/us-central1/v2mime/upload-images`, formData)
                //     .subscribe(
                //         (event: any) => {
                //             resolve({
                //                 ...event,
                //                 name: `${name}${extension}`,
                //                 url: event.gsutilURI,
                //             });
                //         },
                //         (error) => {
                //             console.error('Upload Error:', error);
                //         }
                //     );
                // };
                // fr.readAsDataURL(image);
            }
        });
    }

    newSplitEncodedUrl(URL: string, n: number) {
        const encodedUrl = this.urlsafeBase64Encode(URL) as any;
        let splitUrl = encodedUrl.match(new RegExp('.{1,' + n + '}', 'g')).join('/');

        return `${environment.GCS_URL}/sig/${splitUrl}`;
        // return splitUrl;
    }

    uploadLanguageFiles(file: any) {
        return new Promise((resolve) => {
            const formData: any = {};

            const fr = new FileReader();
            const image: any = file;
            fr.onload = () => {
                const img = new Image() as any;
                console.log('FR.Result');
                img.src = fr.result;
                // formData['file'] = file; //Not handling multipart/form-data for fastify update
                const lastDotIndex = file.name.lastIndexOf('.');
                const name = file.name.substring(0, lastDotIndex);
                const shortenedName = name.length <= 6 ? name : name.substring(0, 6);
                const extension = file.name.substring(lastDotIndex);

                const timestamp = moment().valueOf();

                // Construct new file name with timestamp
                const newFileName = `${shortenedName}_${timestamp}${extension}`;

                // Create a new File object with the same content and the new name
                const renamedFile = new File([file], newFileName, { type: file.type });
                formData['fileName'] = renamedFile.name;
                formData['image'] = img.src;
                formData['type'] = image.type;

                this.http.post(`${environment.fcfApiUrl}/v2mime/upload-languages`, formData).subscribe(
                    (event: any) => {
                        resolve({
                            ...event,
                            name: `${name}${extension}`,
                            url: event.gsutilURI,
                        });
                    },
                    (error) => {
                        console.error('Upload Error:', error);
                    }
                );
            };
            fr.readAsDataURL(image);
        });
    }

    generateSignedUrl(formData: any) {
        return this.http.post(`${environment.fcfApiUrl}/v2mime/generate-signed-url`, { formData: formData });
    }
}
