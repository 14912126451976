/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FirebaseService } from './firebase.service';
import * as moment from 'moment';
import { AppEvent } from '../model/app.modal';
import { SessionModal } from '../model/session.modal';

@Injectable({
    providedIn: 'root',
})
export class ScheduleService {
    constructor(private Firebase: FirebaseService) {}

    event(appId: string, eventId: string) {
        (this.Firebase.getByRef(`apps/${appId}/public/events_list/${eventId}`) as Observable<AppEvent>)
            .pipe(
                map((event: any) => {
                    // const timezoneName = event.time_format.includes('UTC') ? event.time_zone.split(' ')[1] : event.time_zone;
                    if (event) {
                        const timezone = event.time_zone.split(' ')[1] || 'UTC';
                        console.log('timezone', timezone);
                        const now = moment.tz(new Date(), timezone);
                        if (event.end_date_time_unix) {
                            const event_ends_at = moment.tz(event.end_date_time_unix, timezone);
                            if (event_ends_at > now) {
                                if (event.start_date_time_unix) {
                                    const scheduleObject = {
                                        _state: 'schedule_event',
                                        app_id: appId,
                                        event_id: eventId,
                                        starts_at: event.start_date_time_unix,
                                        ends_at: event.end_date_time_unix,
                                        time_zone: timezone,
                                    };
                                    this.Firebase.setByRef(`schedule/events/${appId}_${eventId}`, scheduleObject);
                                } else {
                                    this.removeEvent(appId, eventId);
                                }
                            } else {
                                this.removeEvent(appId, eventId);
                            }
                        } else {
                            this.removeEvent(appId, eventId);
                        }
                    }
                })
            )
            .subscribe();
    }

    removeEvent(appId: string, eventId: string) {
        this.Firebase.setByRef(`schedule/events/${appId}_${eventId}`, null);
    }

    session(appId: string, eventId: string, session: SessionModal) {
        return new Promise((resolve, reject) => {
            const end_date_time_unix = moment(session.start_date_time_unix, 'YYYY-MM-DD HH:mm:ss').add(
                session.duration,
                'second'
            );
            const scheduleObj = {
                _state: 'schedule_session',
                app_id: appId,
                event_id: eventId,
                session_id: session.id,
                starts_at: session.start_date_time_unix,
                ends_at: end_date_time_unix.format('YYYY-MM-DD HH:mm:ss'),
            };
            const currentDate = moment();

            if (session.active_feedback_forms && session.type !== 'basic' && end_date_time_unix.isAfter(currentDate)) {
                this.Firebase.updateByRef(
                    `schedule/sessionRatingNotification/${appId}_${eventId}_${session.id}`,
                    scheduleObj
                ).then(() => {
                    resolve(true);
                });
            } else {
                this.Firebase.setByRef(
                    `schedule/sessionRatingNotification/${appId}_${eventId}_${session.id}`,
                    null
                ).then(() => {
                    resolve(true);
                });
            }
        });
    }
}
