import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import * as _ from 'lodash';
import { Observable, shareReplay, switchMap, take, timer } from 'rxjs';
import { StripeProduct } from '../model/stripe.model';
import { CookieService } from 'ngx-cookie-service';

const CACHE_SIZE = 1;
const REFRESH_INTERVAL = 3600 * 1000;

@Injectable({
    providedIn: 'root',
})
export class StripeService {
    constructor(private http: HttpClient, private Firebase: FirebaseService, private Cookies: CookieService) {}

    public cacheStripeProduct$!: Observable<any> | null;

    getProducts() {
        if (!this.cacheStripeProduct$) {
            this.cacheStripeProduct$ = (
                this.http.get(`${environment.fcfApiUrl}/v2payments/stripe/products`) as Observable<StripeProduct[]>
            ).pipe(shareReplay(CACHE_SIZE));

            return this.cacheStripeProduct$;
            // timer$.pipe(shareReplay(CACHE_SIZE));
            // return this.http.get(`${environment.fcfApiUrl}/v2payments/stripe/products`) as Observable<StripeProduct[]>;
        } else {
            return this.cacheStripeProduct$;
        }
    }

    getCoupans() {
        return this.http.get(`${environment.fcfApiUrl}/v2payments/stripe/coupons`);
    }

    getCustomer() {
        return this.http.get(`${environment.fcfApiUrl}/checkout/customer`);
    }

    checkOutSession(checkOut: any) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/create-checkout-session`, {
            checkOut,
        });
    }

    getChargeObj() {
        return this.http.get(`${environment.fcfApiUrl}/checkout/chargeObj`);
    }

    // getSubscriptions() {
    //     return this.http.get(`${environment.fcfApiUrl}/checkout/subscriptions`);
    // }

    getSubData(id: string) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/subscription`, {
            subscription: id,
        });
    }

    enableStripeConnect(user: any) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/enable-stripe-connect`, {
            user,
        });
    }

    generateAccountLink(data: any) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/generate-account-link`, {
            data,
        });
    }

    getStripeAcc(id: any) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/get-stripe-account`, {
            id,
        });
    }

    getUpdateCustomer(user: any) {
        // console.log('')
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/customer`, {
            user: user,
        });
    }

    getStripeCustomerPortal() {
        return new Promise((resolve, reject) => {
            const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
            this.Firebase.getByRef(`/customers/${customerId}/stripeId`)
                .pipe(take(1))
                .subscribe((sId: string | null | undefined) => {
                    if (sId) {
                        const prepareData = {
                            customerId: sId,
                            returnURL: window.location.href,
                        };

                        this.http
                            .post(`${environment.fcfApiUrl}/v2payments/stripe/customer-portal`, prepareData)
                            .subscribe((res) => {
                                resolve(res);
                            });
                    } else {
                        resolve(null);
                    }
                });
        });
    }

    // createDirectStripeCharge(data: any) {
    //     // console.log('')
    //     return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/stripe-direct-charge`, {
    //         data,
    //     });
    // }

    getPaymentIntent(data: any) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/get-payment-intent`, {
            data,
        });
    }

    getCustomerSession(id: string) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/create-customer-session`, {
            customerId: id,
        });
    }

    getTransactionDetails(data: any) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/get-transaction-data`, {
            data,
        });
    }

    getMethodDetails(data: any) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/get-transaction-method-data`, {
            data,
        });
    }

    getTransactionDetailsByChargeID(chargeId: string, connectedAccountId: string) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/get-transaction-details`, {
            chargeId,
            connectedAccountId,
        });
    }
    sendRefundRequest(customerId: string, eventId: string, refund: number, transactionId: string) {
        return this.http.post(`${environment.fcfApiUrl}/v2payments/stripe/refund-ticket-amount`, {
            customerId,
            eventId,
            refund,
            transactionId,
        });
    }

    processToEnableStripeConnect(customerId: string, currencyCode: string, countryCode: string, eventId: string) {
        // console.log('customerId', customerId);
        // console.log('currencyCode', currencyCode);
        // console.log('countryCode', countryCode);
        return new Promise((resolve, reject) => {
            // combineLatest([])
            this.Firebase.getByRef(`customers/${customerId}`)
                .pipe(take(1))
                .subscribe((customer: any) => {
                    const accountOwner = _.keys(customer.teamMembers).filter(
                        (tm: any) => customer.teamMembers[tm].toLowerCase() === 'owner'
                    );

                    this.Firebase.getByRef(`users/${accountOwner}`)
                        .pipe(take(1))
                        .subscribe((u: any) => {
                            // const email = u.email;
                            // console.log('Email', window.location.href);

                            const prepareUserData: any = {
                                country: countryCode || 'US',
                                currency: currencyCode || 'usd',
                                email: u.email,
                                url: window.location.href,
                                customerId: customerId,
                                eventId: eventId,
                                // companyName: customer.companyName || u.first_name + ' ' + u.last_name || '',
                            };

                            // if (customer.address) {
                            //     prepareUserData.address = {
                            //         city: customer.address.addressLocality || null,
                            //         country: customer.address.addressCountry || customer.address.country || null,
                            //         line1: customer.address.streetAddress || null,
                            //         line2: customer.address.addressLine2 || null,
                            //         postal_code: customer.address.postalCode || null,
                            //         // state: customer.address.addressRegion || null,
                            //     };

                            //     if (customer.address.state) {
                            //         // const state
                            //         this.utility.getStateCode(customer.address.state).subscribe((data) => {
                            //             console.log('===data', data);
                            //         });
                            //     } else {
                            //         // this.enableStripeConnect(prepareUserData).subscribe((resp) => {
                            //         //     console.log('Resp', resp);
                            //         //     resolve(true);
                            //         // });
                            //     }
                            // } else {
                            //     // this.enableStripeConnect(prepareUserData).subscribe((resp) => {
                            //     //     console.log('Resp', resp);
                            //     //     resolve(true);
                            //     // });
                            // }

                            // console.log('====prepareUserData', prepareUserData);

                            this.enableStripeConnect(prepareUserData).subscribe((resp) => {
                                // console.log('Resp', resp);
                                resolve(resp);
                            });
                        });
                });
        });
    }
}
