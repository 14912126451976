import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { AngularFireModule } from '@angular/fire/compat';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { ReCaptchaEnterpriseProvider, initializeAppCheck, provideAppCheck } from '@angular/fire/app-check';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { RequestHeaderInterceptor } from './interceptor/request-header.interceptor';
import { Loader } from '@googlemaps/js-api-loader';

import { DecimalPipe } from '@angular/common';

declare global {
    // eslint-disable-next-line no-var
    var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

(function setupAppCheck() {
    // if (!environment.production) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = false;
    // }
})();

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        MatSnackBarModule,
        AngularSvgIconPreloaderModule.forRoot({
            configUrl: '../../../../assets/icons.json',
        }),
        // AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideStorage(() => getStorage()),

        provideAppCheck(() =>
            initializeAppCheck(getApp(), {
                provider: new ReCaptchaEnterpriseProvider(environment.recaptchaEnterpriseKey),
                isTokenAutoRefreshEnabled: true,
            })
        ),
        provideAuth(() => getAuth()),
        provideDatabase(() => getDatabase()),
        provideFunctions(() => getFunctions()),
        provideMessaging(() => getMessaging()),
    ],
    providers: [
        CookieService,
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: environment.googleMapsApiKey,
                libraries: ['places'],
            }),
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestHeaderInterceptor,
            multi: true,
        },
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        DecimalPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        if (environment.cookie_domain !== 'localhost') {
            console.warn = function () {};
        }
        if (environment.production) {
            console.log = function () {};
        }
    }
}
