import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import * as _ from 'lodash';
import { UtilityService } from './utility.service';
import { FilestackService } from './filestack.service';
import { AbstarckPage } from '../shared/abstract-components/abstarck-page';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ScavengerTransformerService } from './scavenger-transformer.service';
import { DataSnapshot, DatabaseReference } from '@angular/fire/compat/database/interfaces';
import { scavengerHunts } from '../model/scavengerHunts.modal';
import html2canvas from 'html2canvas';
import { combineLatest, defer, first, from, map, of, switchMap } from 'rxjs';
import QrCodeWithLogo from 'qrcode-with-logos';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class ScavengerService extends AbstarckPage {
    entityPath = '';
    shRef!: DatabaseReference;
    constructor(
        public override Firebase: FirebaseService,
        private Utilities: UtilityService,
        private fileStack: FilestackService,
        db: AngularFireDatabase,
        transformer: ScavengerTransformerService
    ) {
        super(transformer, db);
    }

    init(event_code: string, app_id: string) {
        this.appId = app_id;
        this.eventId = event_code;
        this.appRef = this.db.database.ref('apps').child(app_id);
        this.publicRef = this.appRef.child('public');
        this.dataRef = this.publicRef.child(`/events_data/${event_code}`);
        this.entityPath = 'scavenger_hunts';
        this.shRef = this.dataRef.child('scavenger_hunts');
    }

    getEntity(pageId: string) {
        // let def = this.$q.defer();
        const promise$ = new Promise((resolve) => {
            this.dataRef
                .child(this.entityPath)
                .orderByChild('page_id')
                .equalTo(pageId)
                .once('value')
                .then((snapshot) => {
                    const entity = this.resultsToItem(snapshot);
                    if (entity) {
                        resolve(entity);
                    } else {
                        resolve({
                            instructional_text:
                                'Search the venue for Scavenger Hunt QR codes, scan them to complete the phrase below and stand a chance of winning great prizes!',
                            phrase: '',
                            is_generated_qr: false,
                        });
                    }
                    resolve(entity);
                });
        });

        return defer(() => from(promise$));
    }

    override create(data: scavengerHunts) {
        // let def = this.$q.defer();
        return new Promise((resolve) => {
            const newShRef = this.shRef.push();
            const shKey = newShRef.key as string;
            const sh: scavengerHunts = {
                instructional_text: data.instructional_text,
                is_generated: false,
                number_of_codes: data.number_of_codes,
                page_id: data.page_id,
                phrase: data.phrase,
                is_generated_qr: false,
            };
            // console.log('create', data);
            newShRef.set(sh, () => {
                sh.id = shKey;
                resolve(sh);
            });
        });
    }

    override update(data: scavengerHunts) {
        // let def = this.$q.defer();
        return new Promise((resolve) => {
            // console.log('update', data);
            this.shRef.child(data.id as string).update(
                {
                    instructional_text: data.instructional_text,
                    number_of_codes: data.number_of_codes,
                    phrase: data.phrase,
                    is_generated: false,
                    unique_id: null,
                    letters: null,
                    is_generated_qr: data.is_generated_qr || false,
                },
                () => {
                    data.is_generated = false;
                    resolve(data);
                }
            );
        });
    }

    resultsToItem(snapshot: DataSnapshot) {
        const data = snapshot.val();
        const result: any[] = [];
        if (data) {
            this._.forOwn(data, (val, key) => {
                val.id = key;
                result.push(val);
            });
        }
        return result.length ? result[0] : null;
    }

    processQR(scavengerHuntId: string, eventLogo: string | null, eventColor?: string) {
        return new Promise((resolve, reject) => {
            (
                this.Firebase.pushByRef(`qr_pairing`, {
                    app_id: this.appId,
                    event_id: this.eventId,
                    scavenger_hunt_id: scavengerHuntId,
                }) as Promise<string>
            ).then((uniqueId) => {
                // console.log('uniqueId', uniqueId);
                this.generate(this.appId, this.eventId, scavengerHuntId, uniqueId, eventLogo, eventColor)
                    .then((res) => {
                        // console.log('res', res);
                        resolve(res);
                    })
                    .catch((error) => {
                        console.log('processQR code error', error);
                        reject(error);
                    });
            });
        });
    }

    generate(
        appId: string,
        eventId: string,
        scavengerHuntId: string,
        uniqueId: string,
        eventLogo: string | null,
        eventColor?: string
    ) {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`apps/${appId}/public/events_data/${eventId}/scavenger_hunts/${scavengerHuntId}`)
                .pipe(first())
                .subscribe(
                    (sc: any) => {
                        if (!sc) {
                            reject('Scavenger Hunt NOT FOUND');
                        } else if (sc.phrase === undefined || sc.number_of_codes === undefined) {
                            reject('Scavenger Hunt pharse/number of codes NOT FOUND');
                        }
                        this.Firebase.updateByRef(
                            `apps/${appId}/public/events_data/${eventId}/scavenger_hunts/${scavengerHuntId}`,
                            {
                                is_generated: false,
                                unique_id: uniqueId,
                                letters: null,
                            }
                        )
                            .then(() => {
                                const number_of_codes = sc.number_of_codes !== undefined ? sc.number_of_codes : 1;
                                const pairs: any = {};
                                // console.log(sc, 'sc');
                                if (sc.phrase !== undefined) {
                                    sc.phrase.split('').forEach((item: any, index: number) => {
                                        pairs[index] = item;
                                    });
                                }
                                const results: any = {};
                                _.forOwn(pairs, (val, key: any) => {
                                    if (val !== ' ') {
                                        results[key] = val;
                                    }
                                });
                                const keys = _.shuffle(_.keys(results));
                                const chunks = this._chunks(keys, number_of_codes, true);
                                const data: any = [];
                                chunks.forEach((chunk) => {
                                    const obj: any = {};
                                    chunk.forEach((key) => {
                                        obj[`index_${key}`] = results[key];
                                    });
                                    data.push(obj);
                                });

                                const uid = this.Utilities.generateUID();
                                // console.log('data :------', data);

                                const promises: any = [];

                                // console.log('items', data);

                                data.forEach((item: any) => {
                                    promises.push(
                                        this.Firebase.pushByRef(
                                            `apps/${appId}/public/events_data/${eventId}/scavenger_hunts/${scavengerHuntId}/letters`,
                                            item
                                        )
                                    );
                                });

                                Promise.all(promises).then((code_ids) => {
                                    // console.log('Code_ids', code_ids);
                                    const QRCodePromises: any[] = [];
                                    code_ids.forEach((code_id, index) => {
                                        QRCodePromises.push(
                                            this.generateScavengerQR(
                                                {
                                                    unique_id: uniqueId,
                                                    code_id,
                                                },
                                                index,
                                                appId,
                                                eventId,
                                                scavengerHuntId,
                                                eventLogo,
                                                eventColor
                                            )
                                        );
                                        // this.generateScavengerQR(
                                        //     {
                                        //         unique_id: uniqueId,
                                        //         code_id,
                                        //     },
                                        //     index,
                                        //     appId,
                                        //     eventId,
                                        //     scavengerHuntId,
                                        //     eventLogo
                                        // ).then(() => {
                                        //     index++;
                                        // });
                                    });

                                    Promise.all(QRCodePromises).then(() => {
                                        console.log('All qr codes were generated');
                                        this.Firebase.createScavengerHuntTask(appId, eventId, scavengerHuntId).then(
                                            () => {
                                                resolve(data);
                                            }
                                        );
                                    });
                                });
                                // async.eachSeries(
                                // 	data,
                                // 	(item, next) => {
                                // 		// console.log(index, uid);
                                // 		// Generate QR CODE
                                // 		console.log(index, item);
                                // 		this.Firebase.pushByRef(
                                // 			`apps/${appId}/public/events_data/${eventId}/scavenger_hunts/${scavengerHuntId}/letters`,
                                // 			item
                                // 		).then((code_id) => {
                                // 			this.generateScavengerQR(
                                // 				{
                                // 					unique_id: uniqueId,
                                // 					code_id
                                // 				},
                                // 				uid,
                                // 				index,
                                // 				appId,
                                // 				eventId,
                                // 				scavengerHuntId
                                // 			).then(() => {
                                // 				index++;
                                // 				next();
                                // 			});
                                // 		});
                                // 	},
                                // 	() => {
                                // 		console.log('All qr codes were generated');
                                // 		this.Firebase.createScavengerHuntTask(appId, eventId, scavengerHuntId).then(() => {
                                // 			resolve(data);
                                // 		});
                                // 	}
                                // );
                            })
                            .catch((err) => {
                                console.log(err);
                                reject(err);
                            });
                    },
                    (err) => {
                        console.log(err);
                        reject(err);
                    }
                );
        });
    }

    _chunks(a: any[], n: number, balanced: any) {
        if (n < 2) {
            return [a];
        }
        const len = a.length;
        const out = [];
        let i = 0;
        let size: any;
        if (len % n === 0) {
            size = Math.floor(len / n);
            while (i < len) {
                out.push(a.slice(i, (i += size)));
            }
        } else if (balanced) {
            while (i < len) {
                size = Math.ceil((len - i) / n--);
                out.push(a.slice(i, (i += size)));
            }
        } else {
            n--;
            size = Math.floor(len / n);
            if (len % size === 0) size--;
            while (i < size * n) {
                out.push(a.slice(i, (i += size)));
            }
            out.push(a.slice(size * n));
        }
        return out;
    }

    async generateScavengerQR(
        data: any,
        index: number,
        appId: string,
        eventId: string,
        scavengerHuntId: string,
        eventIcon: any,
        color?: string
    ) {
        // console.log('event-logo', eventIcon);
        // const qrCode = new QRCodeStyling({
        //     width: 380,
        //     height: 380,
        //     margin: 30,
        //     data: JSON.stringify(data),
        //     image: eventIcon ? eventIcon : undefined,
        //     type: 'canvas',
        //     dotsOptions: {
        //         color: '#000000',
        //         type: 'square',
        //     },
        //     backgroundOptions: {
        //         color: '#ffffff',
        //     },
        //     imageOptions: {
        //         crossOrigin: 'anonymous',
        //         margin: 10,
        //     },
        // });

        const iDiv = document.createElement('div') as HTMLDivElement;
        iDiv.className = 'qr-code-conatiner';
        iDiv.id = `QRcode-${index}`;
        iDiv.style.backgroundColor = color ? color : '#635BFF';
        document.getElementsByTagName('body')[0].append(iDiv);

        const innerDiv = document.createElement('div') as HTMLDivElement;
        innerDiv.className = 'canvas';
        iDiv.appendChild(innerDiv);

        // create text el
        const textEl = document.createElement('h4') as HTMLHeadingElement;
        textEl.innerHTML = 'Scan Me';
        iDiv.appendChild(textEl);

        // console.log('event-icon', eventIcon);
        if (eventIcon) {
            let base64data: any;
            var reader = new FileReader();
            reader.readAsDataURL(eventIcon);
            reader.onloadend = async () => {
                base64data = reader.result;
                // let mySrc: any = 'data:image/png;base64,' + base64data;
                await new QrCodeWithLogo({
                    content: JSON.stringify(data),
                    width: 1276,
                    // download: true,
                    logo: {
                        src: base64data ? base64data : '',
                        borderColor: '#FFFFFF',
                    },
                })
                    .getCanvas()
                    .then((data) => {
                        innerDiv.appendChild(data);
                        this.htmltoPDF(appId, eventId, index, scavengerHuntId, color);
                    });
            };
        } else {
            await new QrCodeWithLogo({
                content: JSON.stringify(data),
                width: 1276,
            })
                .getCanvas()
                .then((data) => {
                    innerDiv.appendChild(data);
                    this.htmltoPDF(appId, eventId, index, scavengerHuntId, color);
                });
        }
    }

    htmltoPDF(appId: string, eventId: string, index: number, scavengerHuntId: string, color?: string) {
        html2canvas(document.getElementById(`QRcode-${index}`) as HTMLDivElement, {
            backgroundColor: color ? color : '#635BFF',
        }).then(async (canvas) => {
            // const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height], true);
            // pdf.addImage(imgData, 30, 30, canvas.width - 60, canvas.height - 60);
            // const blob = pdf.output('blob');
            const blob = canvas.toDataURL('image/jpeg');
            document.getElementById(`QRcode-${index}`)?.remove();
            fetch(blob)
                .then((res) => res.blob())
                .then((imgData) => {
                    this.fileStack
                        .uploadFile2(
                            new File([imgData], `scavenger_qr_code_${appId}_${eventId}_${index}`, {
                                type: 'image/jpeg',
                            })
                        )
                        .then((resp: any) => {
                            this.Firebase.updateByRef(
                                `apps/${appId}/public/events_data/${eventId}/scavenger_hunts/${scavengerHuntId}`,
                                {
                                    [`scavenger_qr_code_${index}`]: `${resp.gsutilURI}`,
                                }
                            );
                        });
                })
                .catch((error) => {
                    console.error('blob fetch failed:', error);
                });
        });
    }

    scavengerHuntReport(appId: string, eventId: string) {
        return combineLatest([
            this.Firebase.getByRef(`apps/${appId}/public/events_data/${eventId}/scavenger_hunts_completed`),
            this.Firebase.getByRef(`apps/${appId}/public/events_data/${eventId}/scavenger_hunts`),
        ]).pipe(
            switchMap((values: any): any => {
                if (values[0] && values[1]) {
                    const scavengerHunt = values[1];
                    const completeSHIds = _.keys(values[0]);

                    const observabels$: any[] = [];
                    const returnData: any[] = [];
                    let scavengerHuntId: any;
                    const completedCodes: any = {};

                    // const returnData: any[] = [];
                    completeSHIds.forEach((id: any) => {
                        if (scavengerHunt[id]) {
                            const completedUsersId = _.keys(values[0][id]);

                            // console.log('=============userId', completedUsersId);

                            completedUsersId.forEach((userId: string) => {
                                let numberOfCompletedQR: number = 0;
                                // console.log('=============userId', `apps/${appId}/public/users/${userId}`);
                                observabels$.push(
                                    this.Firebase.getByRef(`apps/${appId}/public/users/${userId}`).pipe(
                                        map((val) => ({ ...val, id: userId }))
                                    )
                                );

                                numberOfCompletedQR = _.keys(
                                    values[0][id]?.[userId]?.['scavenger_hunts_scanned_data']
                                ).length;
                                completedCodes[userId] = numberOfCompletedQR;
                            });

                            scavengerHuntId = id;
                            // numberOfCodes = scavengerHunt[id].number_of_codes || 0;
                        }
                    });

                    if (observabels$.length) {
                        return combineLatest(observabels$).pipe(
                            map((users: any): any => {
                                users.forEach((u: any) => {
                                    // console.log('===u', u);
                                    const name = u.title
                                        ? u.title
                                        : u.firstName || u.lastName
                                        ? (u.firstName.trim() + ' ' + u.lastName.trim()).trim()
                                        : '';
                                    returnData.push({
                                        scavengerHuntId,
                                        numberOfCodes: completedCodes[u.id],
                                        name: name,
                                        email: u.email_address,
                                        profileIcon: u.icon || null,
                                        firstName: u.firstName?.trim() || '',
                                        lastName: u.lastName?.trim() || '',
                                    });
                                });

                                return returnData;
                            })
                        );
                    } else {
                        return of([]);
                    }
                } else {
                    return of([]);
                }
            })
        );
        // return this.Firebase.getByRef(
        //     `apps/${appId}/public/events_data/${eventId}/
        // scavenger_hunts_completed`
        // ).pipe(
        //     map((value: any) => {
        //         if (value) {
        //             const;
        //         }
        //     })
        // );
    }

    downloadScavengerDetails(data: any, splitAttendeeName = false) {
        return new Promise((resolve, reject) => {
            let fields = ['Name', 'Email Address', 'Number Of Scanned Codes'];
            if (splitAttendeeName) {
                fields = ['First Name', 'Last Name', 'Email Address', 'Number Of Scanned Codes'];
            }
            const workbook = new Excel.Workbook();
            const worksheet = workbook.addWorksheet('My Sheet', { properties: { tabColor: { argb: 'FFC0000' } } });
            worksheet.addRow([...fields]);
            worksheet.getRow(1).font = { bold: true };
            worksheet.addRow(Array(fields.length).fill(' '));
            data.forEach((user: any) => {
                const row: any = [];
                if (splitAttendeeName) {
                    row.push(user.firstName);
                    row.push(user.lastName);
                } else {
                    row.push(user.name);
                }
                row.push(user.email);
                row.push(user.numberOfCodes);
                // row.push(user.departure);
                // row.push(user.arrival);
                // row.push(user.stop);
                // if (user.departureDate) {
                //     row.push(
                //         moment(user.departureDate).format(eventDateFormat) +
                //             ' ' +
                //             moment(user.departureDate).format(format) +
                //             ' (UTC' +
                //             user.departureOffset +
                //             ')'
                //     );
                // } else {
                //     row.push('');
                // }
                // if (user.arrivalDate) {
                //     row.push(
                //         moment(user.arrivalDate).format(eventDateFormat) +
                //             ' ' +
                //             moment(user.arrivalDate).format(format) +
                //             ' (UTC' +
                //             user.arrivalOffset +
                //             ')'
                //     );
                // } else {
                //     row.push('');
                // }

                worksheet.addRow(row);
            });

            worksheet.getColumn(1).width = 30;
            _.forEach(fields, (field, index) => {
                if (['Number Of Scanned Codes'].includes(field)) {
                    worksheet.getColumn(index + 1).width = 26;
                } else if (field === 'Email Address') {
                    worksheet.getColumn(index + 1).width = 35;
                } else if (field === 'Last Name') {
                    worksheet.getColumn(index + 1).width = 15;
                } else if (field === 'First Name') {
                    worksheet.getColumn(index + 1).width = 15;
                } else if (field === 'Prefix') {
                    worksheet.getColumn(index + 1).width = 15;
                } else if (field === 'Suffix') {
                    worksheet.getColumn(index + 1).width = 15;
                }
            });
            workbook.xlsx.writeBuffer().then((buffer) => {
                const data: Blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(data, 'scavenger_hunt_details.xlsx');
                resolve(true);
            });
        });
    }
}
